/* generated by Svelte v1.64.1 */
"use strict";
var Settings = require("../herodotus-settings.js");

var Menu = require("./menu.component");

var Topbar = require("./topbar.component");

var marked = require("marked");

var ajax = require("@fdaciuk/ajax");

var Hammer = require("hammerjs");

var Prism = require("prismjs");
Settings = (Settings && Settings.__esModule) ? Settings["default"] : Settings;
Menu = (Menu && Menu.__esModule) ? Menu["default"] : Menu;
Topbar = (Topbar && Topbar.__esModule) ? Topbar["default"] : Topbar;
marked = (marked && marked.__esModule) ? marked["default"] : marked;
ajax = (ajax && ajax.__esModule) ? ajax["default"] : ajax;
Hammer = (Hammer && Hammer.__esModule) ? Hammer["default"] : Hammer;
Prism = (Prism && Prism.__esModule) ? Prism["default"] : Prism;

function data() {
  return {
    fontSize: 2,
    documentation: '',
    documentationHTML: ''
  }
};

function swipeleft(node, callback) {
  let h = new Hammer(node);
  h.on('swipeleft', callback);
};

function swiperight(node, callback) {
  let h = new Hammer(node);
  h.on('swiperight', callback);
};

var methods = {
  getParentId(node) {
    if(node === 'end') {
      return null;
    }
      
    if(node.id && ['H1', 'H2'].indexOf(node.tagName) > -1) {
      return { id: node.id, title: node.textContent };
    } else {
      return this.getParentId(node.previousElementSibling || 'end');
    }
  },
  generateSearchList() {
    let children = [...document.querySelector('.doc-container').children];
    let objectList = [];
      
    children.forEach((node) => {
      let sectionData = this.getParentId(node);
      if(sectionData) {
        objectList.push({
          id: sectionData.id,
          title: sectionData.title,
          content: node.textContent 
        });
      }
    });

    return objectList;
  },
  handleFontSize (event) {
    let action = event.action;
    switch (action) {
      case 'INCREASE':
      this.increaseFont();
      break;
      case 'DECREASE':
      this.decreaseFont();
      break;
    }
  },
  handleMobileMenu (event) {
    let action = event.action;
    switch (action) {
      case 'TOGGLE':
      this.refs.topbar.set({isMenuOpen: this.refs.menu.toggle()});
      break;
      case 'CLOSE':
      this.refs.menu.close();
      this.refs.topbar.set({isMenuOpen: false});
      break;
      case 'OPEN':
      this.refs.menu.open();
      this.refs.topbar.set({isMenuOpen: true});
      break;
    }
  },
  increaseFont () {
    let fontSize = this.get('fontSize');
    if (fontSize >= 4) {
      return;
    }
    this.set({fontSize: fontSize + 1});
  },
  decreaseFont () {
    let fontSize = this.get('fontSize');
    if (fontSize <= 1) {
      return;
    }
    this.set({fontSize: fontSize - 1});
  },
  registerDocumentation () {
    let DOC_URL;
    
    if(Settings.allow_remote_documentation) {
      DOC_URL = Settings.remote_documentation_url;
    } else {
      DOC_URL = 'documentation.md';
    }

    ajax().get(DOC_URL).then((res, xhr) => {
      // Print documentation HTML
      this.set({
        documentation: res,
        documentationHTML: marked(res)
      });

      // Send menu to menu component
      this.refs.menu.startMenu(this.generateMenu());

      // Send search objects to topbar and then search comp
      this.refs.topbar.refs.searchlist.set({
        searchList: this.generateSearchList()
      });

      // Make tables responsive
      this.responsiveTables();

      // Highlight code
      Prism.highlightAll();
    });
  },
  responsiveTables() {
    const tables = Array.from(document.querySelectorAll('.doc-container table'));

    tables.forEach((table) => {
      const responsiveElement = document.createElement('div');
      
      responsiveElement.classList.add('responsive-table');
      table.parentElement.insertBefore(responsiveElement, table);
      responsiveElement.appendChild(table);
    });
  },
  generateMenu () {
    let docElements = this.refs.docContainer;
    let headingItems = docElements.querySelectorAll('h1, h2, h3');
    let menuItems = [];
    let menuItemsFlat = [];
    let currH1;
    let currH2;

    headingItems.forEach((item) => {
      menuItemsFlat.push({
        type: item.nodeName.toLowerCase(),
        label: item.textContent,
        id: item.id
      });

      if (item.nodeName.toLowerCase() === 'h1') {
        menuItems.push({
          type: item.nodeName.toLowerCase(),
          label: item.textContent,
          id: item.id
        });
        currH1 = menuItems[menuItems.length - 1];
        currH1.children = [];
        return;
      } else if (item.nodeName.toLowerCase() === 'h2') {
        currH1.children.push({
          type: item.nodeName.toLowerCase(),
          label: item.textContent,
          id: item.id
        });
        currH2 = currH1.children[currH1.children.length - 1];
        currH2.children = [];
        return;
      } else {
        currH2.children.push({
          type: item.nodeName.toLowerCase(),
          label: item.textContent,
          id: item.id
        });
      }
    });

    return {menuItems: menuItems, menuItemsFlat: menuItemsFlat};
  }
};

function oncreate() {
  this.registerDocumentation();
};

function create_main_fragment(component, state) {
	var div, text, text_1, div_1, div_2, text_2, div_3, div_3_class_value;

	var topbar = new Topbar({
		root: component.root
	});

	topbar.on("mobilemenu", function(event) {
		component.handleMobileMenu(event);
	});
	topbar.on("fontsize", function(event) {
		component.handleFontSize(event);
	});

	component.refs.topbar = topbar;

	var menu = new Menu({
		root: component.root
	});

	menu.on("mobilemenu", function(event) {
		component.handleMobileMenu(event);
	});

	component.refs.menu = menu;

	return {
		c: function create() {
			div = createElement("div");
			topbar._fragment.c();
			text = createText("\n  ");
			menu._fragment.c();
			text_1 = createText("\n  ");
			div_1 = createElement("div");
			div_2 = createElement("div");
			text_2 = createText("\n    ");
			div_3 = createElement("div");
			this.h();
		},

		h: function hydrate() {
			div_2.className = "menu-swipper";
			div_3.className = div_3_class_value = "doc-container font-" + state.fontSize;
			div_1.className = "body";
			div.className = "MainComp";
		},

		m: function mount(target, anchor) {
			insertNode(div, target, anchor);
			topbar._mount(div, null);
			appendNode(text, div);
			menu._mount(div, null);
			appendNode(text_1, div);
			appendNode(div_1, div);
			appendNode(div_2, div_1);
			appendNode(text_2, div_1);
			appendNode(div_3, div_1);
			div_3.innerHTML = state.documentationHTML;
			component.refs.docContainer = div_3;
		},

		p: function update(changed, state) {
			if (changed.documentationHTML) {
				div_3.innerHTML = state.documentationHTML;
			}

			if ((changed.fontSize) && div_3_class_value !== (div_3_class_value = "doc-container font-" + state.fontSize)) {
				div_3.className = div_3_class_value;
			}
		},

		u: function unmount() {
			div_3.innerHTML = '';

			detachNode(div);
		},

		d: function destroy() {
			topbar.destroy(false);
			if (component.refs.topbar === topbar) component.refs.topbar = null;
			menu.destroy(false);
			if (component.refs.menu === menu) component.refs.menu = null;
			if (component.refs.docContainer === div_3) component.refs.docContainer = null;
		}
	};
}

function SvelteComponent(options) {
	init(this, options);
	this.refs = {};
	this._state = assign(data(), options.data);

	var self = this;
	var _oncreate = function() {
		var changed = { fontSize: 1, documentationHTML: 1 };
		oncreate.call(self);
		self.fire("update", { changed: changed, current: self._state });
	};

	if (!options.root) {
		this._oncreate = [];
		this._beforecreate = [];
		this._aftercreate = [];
	}

	this._fragment = create_main_fragment(this, this._state);

	this.root._oncreate.push(_oncreate);

	if (options.target) {
		this._fragment.c();
		this._mount(options.target, options.anchor);

		this._lock = true;
		callAll(this._beforecreate);
		callAll(this._oncreate);
		callAll(this._aftercreate);
		this._lock = false;
	}
}

assign(SvelteComponent.prototype, {
 	destroy: destroy,
 	get: get,
 	fire: fire,
 	observe: observe,
 	on: on,
 	set: set,
 	teardown: destroy,
 	_set: _set,
 	_mount: _mount,
 	_unmount: _unmount,
 	_differs: _differs
 });
assign(SvelteComponent.prototype, methods);

SvelteComponent.prototype._recompute = noop;

function createElement(name) {
	return document.createElement(name);
}

function createText(data) {
	return document.createTextNode(data);
}

function insertNode(node, target, anchor) {
	target.insertBefore(node, anchor);
}

function appendNode(node, target) {
	target.appendChild(node);
}

function detachNode(node) {
	node.parentNode.removeChild(node);
}

function init(component, options) {
	component._handlers = blankObject();
	component._bind = options._bind;

	component.options = options;
	component.root = options.root || component;
	component.store = component.root.store || options.store;
}

function assign(tar, src) {
	for (var k in src) tar[k] = src[k];
	return tar;
}

function callAll(fns) {
	while (fns && fns.length) fns.shift()();
}

function destroy(detach) {
	this.destroy = noop;
	this.fire('destroy');
	this.set = this.get = noop;

	if (detach !== false) this._fragment.u();
	this._fragment.d();
	this._fragment = this._state = null;
}

function get(key) {
	return key ? this._state[key] : this._state;
}

function fire(eventName, data) {
	var handlers =
		eventName in this._handlers && this._handlers[eventName].slice();
	if (!handlers) return;

	for (var i = 0; i < handlers.length; i += 1) {
		var handler = handlers[i];

		if (!handler.__calling) {
			handler.__calling = true;
			handler.call(this, data);
			handler.__calling = false;
		}
	}
}

function observe(key, callback, options) {
	var fn = callback.bind(this);

	if (!options || options.init !== false) {
		fn(this.get()[key], undefined);
	}

	return this.on(options && options.defer ? 'update' : 'state', function(event) {
		if (event.changed[key]) fn(event.current[key], event.previous && event.previous[key]);
	});
}

function on(eventName, handler) {
	if (eventName === 'teardown') return this.on('destroy', handler);

	var handlers = this._handlers[eventName] || (this._handlers[eventName] = []);
	handlers.push(handler);

	return {
		cancel: function() {
			var index = handlers.indexOf(handler);
			if (~index) handlers.splice(index, 1);
		}
	};
}

function set(newState) {
	this._set(assign({}, newState));
	if (this.root._lock) return;
	this.root._lock = true;
	callAll(this.root._beforecreate);
	callAll(this.root._oncreate);
	callAll(this.root._aftercreate);
	this.root._lock = false;
}

function _set(newState) {
	var oldState = this._state,
		changed = {},
		dirty = false;

	for (var key in newState) {
		if (this._differs(newState[key], oldState[key])) changed[key] = dirty = true;
	}
	if (!dirty) return;

	this._state = assign(assign({}, oldState), newState);
	this._recompute(changed, this._state);
	if (this._bind) this._bind(changed, this._state);

	if (this._fragment) {
		this.fire("state", { changed: changed, current: this._state, previous: oldState });
		this._fragment.p(changed, this._state);
		this.fire("update", { changed: changed, current: this._state, previous: oldState });
	}
}

function _mount(target, anchor) {
	this._fragment[this._fragment.i ? 'i' : 'm'](target, anchor || null);
}

function _unmount() {
	if (this._fragment) this._fragment.u();
}

function _differs(a, b) {
	return a != a ? b == b : a !== b || ((a && typeof a === 'object') || typeof a === 'function');
}

function noop() {}

function blankObject() {
	return Object.create(null);
}

module.exports = SvelteComponent;